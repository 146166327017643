.profile {
  display: grid;
  justify-content: stretch;
  justify-items: center;
  gap: 2rem;
  width: 100%;

  @media screen and (min-width: 40rem) {
    grid-template-columns: auto auto;
    justify-items: start;
  }
}

.profile__picture {
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  margin-top: -6rem;
  filter: drop-shadow(0px 8px 32px rgba(96, 142, 204, 0.2));
}

.profile__info {
  display: grid;
  gap: 1rem;
  width: 100%;
}

.profile__contact {
  display: grid;
  align-content: center;
  align-items: center;
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  width: 100%;

  @media screen and (min-width: 40rem) {
    grid-auto-flow: column;
  }
}

.profile__social {
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  justify-content: start;
  justify-items: start;
  gap: 1.5rem;
}

.profile__social-link {
  padding-right: 0;
  padding-left: 0;
}