.section {
  display: grid;
  gap: 4rem;
  padding: 4rem 0;
  grid-template-columns: 1fr;
  // justify-content: center;
}

.section--blue-gradient {
  background: linear-gradient(131.48deg, #5686c8 23.74%, #7ea3d5 76.47%);
  color: $color-white;

  .section__title {
    color: $color-white;
  }
}

.section--profile {
  padding: 3rem 0;
}

// Section title
.section__title {
  color: $color-primary;
  font-size: $font-size-xl;

  @media screen and (min-width: 50rem) {
    font-size: $font-size-3xl;
  }
}

.section__subtitle {
  color: $color-primary;
  font-size: $font-size-lg;
  margin: 1rem auto;

  @media screen and (min-width: 50rem) {
    font-size: $font-size-2xl;
  }
}

