.card {
  padding: 2rem;
  background-color: lighten($color-accent, 30);
}

.card--outline {
  background-color: $color-white;
  border: 2px solid lighten($color-accent, 30);
}

.card p:last-child {
  margin-bottom: 0;
}
