body {
  margin: 0;
  padding: 0;
  font-family: $font-family-body;
  color: $color-body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: $line-height-heading;
}

p,
ol,
ul {
  line-height: $line-height-body;
}
