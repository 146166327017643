.hero {
  display: grid;
  align-content: space-between;
  padding-bottom: 4rem;
  background-image: linear-gradient(4deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%),
    url(../images/hero-bg.jpg);
  background-position: center center, center top;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  min-height: 37.5rem;
}

.hero__body {
  display: grid;
  align-content: end;
  height: 100%;
}

.hero__title {
  color: $color-primary;
  font-size: $font-size-2xl;

  @media screen and (min-width: 50rem) {
    font-size: $font-size-4xl;
  }
}

.hero__text {
  font-size: $font-size-md;

  @media screen and (min-width: 50rem) {
    font-size: $font-size-lg;
  }
}
