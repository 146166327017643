.header {
  padding: 2rem 0;
}

.header__content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.header__claim {
  display: none;
  font-size: 1rem;
  color: $color-accent;

  @media screen and (min-width: 40rem) {
    display: block;
  }
}

.header__logo {
  width: 204px;
  height: 140px;
}
