:root {
  --cc-bg: #fff;
  --cc-text: #2d4156;
  --cc-btn-primary-bg: #2d4156;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.c_darkmode {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

.cc_div *, .cc_div :after, .cc_div :before {
  box-sizing: border-box;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  text-transform: none;
  letter-spacing: unset;
  color: inherit;
  box-shadow: none;
  text-align: left;
  visibility: unset;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.2;
  text-decoration: none;
  transition: none;
  animation: none;
}

.cc_div {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  color: #2d4156;
  color: var(--cc-text);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
}

#c-ttl, #s-bl td:before, #s-ttl, .cc_div .b-tl, .cc_div .c-bn {
  font-weight: 600;
}

#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tl, .cc_div .c-bl {
  border-radius: .35em;
}

#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc_div a, .cc_div button, .cc_div input {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.cc_div a {
  border-bottom: 1px solid;
}

.cc_div a:hover {
  border-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

#cm-ov, #cs-ov, .c--anim #cm, .c--anim #s-cnt, .c--anim #s-inr {
  transition: visibility .25s linear, opacity .25s, transform .25s !important;
}

.c--anim .c-bn {
  transition: background-color .25s !important;
}

.c--anim #cm.bar.slide, .c--anim .bar.slide #s-inr, .c--anim #cm.bar.slide + #cm-ov, .c--anim .bar.slide + #cs-ov {
  transition: visibility .4s, opacity .4s, transform .4s !important;
}

#cm.bar.slide, .cc_div .bar.slide #s-inr {
  opacity: 1;
  transform: translateX(100%);
}

#cm.bar.top.slide, .cc_div .bar.left.slide #s-inr {
  opacity: 1;
  transform: translateX(-100%);
}

#cm.slide, .cc_div .slide #s-inr {
  transform: translateY(1.6em);
}

#cm.top.slide {
  transform: translateY(-1.6em);
}

#cm.bar.slide {
  transform: translateY(100%);
}

#cm.bar.top.slide {
  transform: translateY(-100%);
}

.show--consent .c--anim #cm, .show--consent .c--anim #cm.bar, .show--settings .c--anim #s-inr, .show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}

.show--consent .c--anim #cm.box.middle, .show--consent .c--anim #cm.cloud.middle {
  transform: scale(1)translateY(-50%);
}

.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}

.force--consent.show--consent .c--anim #cm-ov, .show--settings .c--anim #cs-ov {
  visibility: visible !important;
  opacity: 1 !important;
}

#cm {
  z-index: 1;
  background: #fff;
  background: var(--cc-bg);
  max-width: 24.2em;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  font-family: inherit;
  line-height: initial;
  padding: 1.1em 1.8em 1.4em;
  position: fixed;
  bottom: 1.25em;
  right: 1.25em;
  transform: scale(.95);
  box-shadow: 0 .625em 1.875em rgba(2, 2, 3, .28);
}

#cc_div #cm {
  display: block !important;
}

#c-ttl {
  margin-bottom: .7em;
  font-size: 1.05em;
}

.cloud #c-ttl {
  margin-top: -.15em;
}

#c-txt {
  font-size: .9em;
  line-height: 1.5em;
}

.cc_div #c-bns {
  justify-content: space-between;
  margin-top: 1.4em;
  display: flex;
}

.cc_div .c-bn {
  color: #40505a;
  color: var(--cc-btn-secondary-text);
  background: #e5ebef;
  background: var(--cc-btn-secondary-bg);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  text-align: center;
  border-radius: 4px;
  flex: 1;
  padding: 1em 1.7em;
  font-size: .82em;
  display: inline-block;
}

#c-bns button + button, #s-c-bn, #s-cnt button + button {
  float: right;
  margin-left: 1em;
}

#s-cnt #s-rall-bn {
  float: none;
}

#cm .c_link:active, #cm .c_link:hover, #s-c-bn:active, #s-c-bn:hover, #s-cnt button + button:active, #s-cnt button + button:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

#s-cnt {
  width: 100%;
  z-index: 101;
  height: 100%;
  visibility: hidden;
  display: table;
  position: fixed;
  top: 0;
  left: 0;
}

#s-bl {
  outline: none;
}

#s-bl .title {
  margin-top: 1.4em;
}

#s-bl .title:first-child, #s-bl .b-bn {
  margin-top: 0;
}

#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1em;
}

#s-cnt .b-bn .b-tl {
  width: 100%;
  background: none;
  padding: 1.3em 6.4em 1.3em 2.7em;
  font-family: inherit;
  font-size: .95em;
  transition: background-color .25s;
  display: block;
  position: relative;
}

#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}

#s-cnt .act .b-bn .b-tl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#s-cnt .b-bn .b-tl:active, #s-cnt .b-bn .b-tl:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl .b-bn {
  position: relative;
}

#s-bl .c-bl {
  border: 1px solid #f1f3f5;
  border-color: var(--cc-section-border);
  margin-bottom: .5em;
  padding: 1em;
  transition: background-color .25s;
}

#s-bl .c-bl:hover {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
}

#s-bl .c-bl:last-child {
  margin-bottom: .5em;
}

#s-bl .c-bl:first-child {
  border: none;
  margin-top: 0;
  margin-bottom: 2em;
  padding: 0;
  transition: none;
}

#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: none;
  background: unset;
}

#s-bl .c-bl.b-ex {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
  border: none;
  padding: 0;
  transition: none;
}

#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2em;
}

#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}

#s-bl .c-bl.b-ex:first-child {
  margin-bottom: .5em;
}

#s-bl .b-acc {
  max-height: 0;
  margin-bottom: 0;
  padding-top: 0;
  display: none;
  overflow: hidden;
}

#s-bl .act .b-acc {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

#s-cnt .p {
  color: #2d4156;
  color: var(--cc-block-text);
  margin-top: .85em;
  font-size: .9em;
  line-height: 1.5em;
}

.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}

#c-vln {
  vertical-align: middle;
  display: table-cell;
  position: relative;
}

#cs {
  width: 100%;
  height: 100%;
  padding: 0 1.7em;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#s-inr {
  height: 100%;
  max-width: 45em;
  opacity: 0;
  height: 100%;
  visibility: hidden;
  margin: 0 auto;
  padding-top: 4.75em;
  padding-bottom: 4.75em;
  position: relative;
  overflow: hidden;
  transform: scale(.96);
  box-shadow: 0 13px 27px -5px rgba(3, 6, 9, .26);
}

#s-bns, #s-hdr, #s-inr {
  background: #fff;
  background: var(--cc-bg);
}

#s-bl {
  overflow-y: auto;
  overflow-y: overlay;
  height: 100%;
  width: 100%;
  padding: 1.3em 2.1em;
  display: block;
  overflow-x: hidden;
}

#s-bns {
  border-top: 1px solid #f1f3f5;
  border-color: var(--cc-section-border);
  height: 4.75em;
  padding: 1em 2.1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cc_div .cc-link {
  color: #253b48;
  color: var(--cc-btn-primary-bg);
  border-bottom: 1px solid #253b48;
  border-color: var(--cc-btn-primary-bg);
  cursor: pointer;
  padding-bottom: 0;
  font-weight: 600;
  text-decoration: none;
  display: inline;
}

.cc_div .cc-link:active, .cc_div .cc-link:hover {
  border-color: rgba(0, 0, 0, 0);
}

#c-bns button:first-child, #s-bns button:first-child {
  color: #fff;
  color: var(--cc-btn-primary-text);
  background: #253b48;
  background: var(--cc-btn-primary-bg);
}

#c-bns.swap button:first-child {
  color: #40505a;
  color: var(--cc-btn-secondary-text);
  background: #e5ebef;
  background: var(--cc-btn-secondary-bg);
}

#c-bns.swap button:last-child {
  color: #fff;
  color: var(--cc-btn-primary-text);
  background: #253b48;
  background: var(--cc-btn-primary-bg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: #253b48;
  background: var(--cc-toggle-bg-on);
}

#c-bns button:first-child:active, #c-bns button:first-child:hover, #c-bns.swap button:last-child:active, #c-bns.swap button:last-child:hover, #s-bns button:first-child:active, #s-bns button:first-child:hover {
  background: #1d2e38;
  background: var(--cc-btn-primary-hover-bg);
}

#c-bns.swap button:first-child:active, #c-bns.swap button:first-child:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}

#s-hdr {
  width: 100%;
  height: 4.75em;
  vertical-align: middle;
  z-index: 2;
  border-bottom: 1px solid #f1f3f5;
  border-color: var(--cc-section-border);
  padding: 0 2.1em;
  display: table;
  position: absolute;
  top: 0;
}

#s-ttl {
  vertical-align: middle;
  font-size: 1em;
  display: table-cell;
}

#s-c-bn {
  width: 1.7em;
  height: 1.7em;
  font-size: 1.45em;
  font-weight: initial;
  margin: 0;
  padding: 0;
  position: relative;
}

#s-c-bnc {
  vertical-align: middle;
  display: table-cell;
}

.cc_div span.t-lb {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#c_policy__text {
  height: 31.25em;
  margin-top: 1.25em;
  overflow-y: auto;
}

#c-s-in {
  height: 100%;
  height: calc(100% - 2.5em);
  max-height: 37.5em;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 688px) {
  #s-bl::-webkit-scrollbar {
    width: .9em;
    height: 100%;
    background: none;
    border-radius: 0 .25em .25em 0;
  }

  #s-bl::-webkit-scrollbar-thumb {
    border: .25em solid var(--cc-bg);
    background: #cfd5db;
    background: var(--cc-webkit-scrollbar-bg);
    border-radius: 100em;
  }

  #s-bl::-webkit-scrollbar-thumb:hover {
    background: #9199a0;
    background: var(--cc-webkit-scrollbar-bg-hover);
  }

  #s-bl::-webkit-scrollbar-button {
    width: 10px;
    height: 5px;
  }
}

.cc_div .b-tg {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  margin: auto;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.2em;
}

.cc_div .b-tg .c-tgl {
  cursor: pointer;
  border: none;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cc_div .b-tg .c-tg {
  background: #919ea6;
  background: var(--cc-toggle-bg-off);
  pointer-events: none;
  transition: background-color .25s, box-shadow .25s;
  position: absolute;
}

.cc_div .b-tg, .cc_div .b-tg .c-tg, .cc_div .b-tg .c-tgl, .cc_div span.t-lb {
  width: 3.4em;
  height: 1.5em;
  border-radius: 4em;
}

.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: #d5dee2;
  background: var(--cc-toggle-bg-readonly);
}

.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}

.cc_div .b-tg .c-tg:after {
  content: "";
  width: 1.25em;
  height: 1.25em;
  box-sizing: content-box;
  background: #fff;
  background: var(--cc-toggle-knob-bg);
  border: none;
  border-radius: 100%;
  transition: transform .25s;
  display: block;
  position: relative;
  top: .125em;
  left: .125em;
  box-shadow: 0 1px 2px rgba(24, 32, 35, .36);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9em);
}

#s-bl table, #s-bl td, #s-bl th {
  border: none;
}

#s-bl tbody tr {
  transition: background-color .25s;
}

#s-bl tbody tr:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}

#s-bl table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#s-bl td, #s-bl th {
  text-align: left;
  vertical-align: top;
  padding: .8em .625em .8em 1.2em;
  font-size: .8em;
}

#s-bl th {
  padding: 1.2em;
  font-family: inherit;
}

#s-bl thead tr:first-child {
  border-bottom: 1px solid #e9edf2;
  border-color: var(--cc-cookie-table-border);
}

.force--consent #cs, .force--consent #s-cnt {
  width: 100vw;
}

#cm-ov, #cs-ov {
  visibility: hidden;
  opacity: 0;
  background: rgba(4, 6, 8, .85);
  background: var(--cc-overlay-bg);
  transition: none;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.c--anim #cs-ov, .force--consent .c--anim #cm-ov, .force--consent.show--consent #cm-ov, .show--settings #cs-ov {
  display: block;
}

#cs-ov {
  z-index: 2;
}

.force--consent .cc_div {
  width: 100%;
  width: 100vw;
  visibility: hidden;
  transition: visibility .25s linear;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.force--consent.show--consent .c--anim .cc_div, .force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}

.force--consent #cm {
  position: absolute;
}

.force--consent #cm.bar {
  width: 100vw;
  max-width: 100vw;
}

html.force--consent.show--consent {
  overflow-y: hidden !important;
}

html.force--consent.show--consent, html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}

.cc_div .act .b-bn .exp:before, .cc_div .b-bn .exp:before {
  border:  solid #2d4156;
  border-color: var(--cc-btn-secondary-text);
  content: "";
  border-width: 0 2px 2px 0;
  margin-right: 15px;
  padding: .2em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1.2em;
  transform: translateY(-50%)rotate(45deg);
}

.cc_div .act .b-bn .b-tl:before {
  transform: translateY(-20%)rotate(225deg);
}

.cc_div .on-i:before {
  border:  solid #fff;
  border-color: var(--cc-toggle-knob-icon-color);
  content: "";
  border-width: 0 2px 2px 0;
  margin: 0 auto;
  padding: .1em .1em .45em;
  display: inline-block;
  position: absolute;
  top: .37em;
  left: .75em;
  transform: rotate(45deg);
}

#s-c-bn:after, #s-c-bn:before {
  content: "";
  height: .6em;
  width: 1.5px;
  background: #444d53;
  background: var(--cc-btn-secondary-text);
  border-radius: 1em;
  margin: 0 auto;
  position: absolute;
  top: .58em;
  left: .82em;
  transform: rotate(45deg);
}

#s-c-bn:after {
  transform: rotate(-45deg);
}

.cc_div .off-i, .cc_div .on-i {
  height: 100%;
  width: 50%;
  text-align: center;
  transition: opacity .15s;
  display: block;
  position: absolute;
  right: 0;
}

.cc_div .on-i {
  opacity: 0;
  left: 0;
}

.cc_div .off-i:after, .cc_div .off-i:before {
  content: " ";
  height: .7em;
  width: .09375em;
  background: #cdd6dc;
  background: var(--cc-toggle-knob-icon-color);
  transform-origin: center;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: .42em;
  right: .8em;
}

.cc_div .off-i:before {
  transform: rotate(45deg);
}

.cc_div .off-i:after {
  transform: rotate(-45deg);
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}

#cm.box.middle, #cm.cloud.middle {
  top: 50%;
  bottom: auto;
  transform: translateY(-37%);
}

#cm.box.middle.zoom, #cm.cloud.middle.zoom {
  transform: scale(.95)translateY(-50%);
}

#cm.box.center {
  margin: 0 auto;
  left: 1em;
  right: 1em;
}

#cm.cloud {
  max-width: 50em;
  text-align: center;
  width: unset;
  margin: 0 auto;
  padding: 1.3em 2em;
  left: 1em;
  right: 1em;
  overflow: hidden;
}

.cc_div .cloud #c-inr {
  width: 100%;
  display: table;
}

.cc_div .cloud #c-inr-i {
  width: 70%;
  vertical-align: top;
  padding-right: 2.4em;
  display: table-cell;
}

.cc_div .cloud #c-txt {
  font-size: .85em;
}

.cc_div .cloud #c-bns {
  min-width: 170px;
  vertical-align: middle;
  display: table-cell;
}

#cm.cloud .c-bn {
  width: 100%;
  margin: .625em 0 0;
}

#cm.cloud .c-bn:first-child {
  margin: 0;
}

#cm.cloud.left {
  margin-right: 1.25em;
}

#cm.cloud.right {
  margin-left: 1.25em;
}

#cm.bar {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  padding: 2em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#cm.bar #c-inr {
  max-width: 32em;
  margin: 0 auto;
}

#cm.bar #c-bns {
  max-width: 33.75em;
}

#cm.bar #cs {
  padding: 0;
}

.cc_div .bar #c-s-in {
  height: 100%;
  max-height: 100%;
  top: 0;
  transform: none;
}

.cc_div .bar #s-bl, .cc_div .bar #s-bns, .cc_div .bar #s-hdr {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.cc_div .bar #cs {
  padding: 0;
}

.cc_div .bar #s-inr {
  max-width: 32em;
  border-radius: 0;
  margin: 0 0 0 auto;
}

.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}

.cc_div .bar #s-bl table, .cc_div .bar #s-bl tbody, .cc_div .bar #s-bl td, .cc_div .bar #s-bl th, .cc_div .bar #s-bl thead, .cc_div .bar #s-bl tr, .cc_div .bar #s-cnt {
  display: block;
}

.cc_div .bar #s-bl thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.cc_div .bar #s-bl tr {
  border-top: 1px solid #e3e7ed;
  border-color: var(--cc-cookie-table-border);
}

.cc_div .bar #s-bl td {
  border: none;
  padding-left: 35%;
  position: relative;
}

.cc_div .bar #s-bl td:before {
  white-space: nowrap;
  content: attr(data-column);
  color: #000;
  color: var(--cc-text);
  text-overflow: ellipsis;
  padding-right: .625em;
  position: absolute;
  left: 1em;
  overflow: hidden;
}

#cm.top {
  top: 1.25em;
  bottom: auto;
}

#cm.left {
  left: 1.25em;
  right: auto;
}

#cm.right {
  left: auto;
  right: 1.25em;
}

#cm.bar.left, #cm.bar.right {
  left: 0;
  right: 0;
}

#cm.bar.top {
  top: 0;
}

@media screen and (max-width: 688px) {
  #cm, #cm.cloud, #cm.left, #cm.right {
    width: auto;
    max-width: 100%;
    margin: 0;
    display: block;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1.4em !important;
  }

  .force--consent #cm, .force--consent #cm.cloud {
    width: auto;
    max-width: 100vw;
  }

  #cm.top {
    top: 1em;
    bottom: auto;
  }

  #cm.bottom {
    top: auto;
    bottom: 1em;
  }

  #cm.bar.bottom {
    bottom: 0;
  }

  #cm.cloud .c-bn {
    font-size: .85em;
  }

  #s-bns, .cc_div .bar #s-bns {
    padding: 1em 1.3em;
  }

  .cc_div .bar #s-inr {
    max-width: 100%;
    width: 100%;
  }

  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }

  #cs {
    border-radius: 0;
    padding: 0;
  }

  #c-s-in {
    max-height: 100%;
    height: 100%;
    top: 0;
    transform: none;
  }

  .cc_div .b-tg {
    right: 1.1em;
    transform: scale(1.1);
  }

  #s-inr {
    border-radius: 0;
    margin: 0;
    padding-bottom: 7.9em;
  }

  #s-bns {
    height: 7.9em;
  }

  #s-bl, .cc_div .bar #s-bl {
    padding: 1.3em;
  }

  #s-hdr, .cc_div .bar #s-hdr {
    padding: 0 1.3em;
  }

  #s-bl table {
    width: 100%;
  }

  #s-inr.bns-t {
    padding-bottom: 10.5em;
  }

  .bns-t #s-bns {
    height: 10.5em;
  }

  .cc_div .bns-t .c-bn {
    padding: .9em 1.6em;
    font-size: .83em;
  }

  #s-cnt .b-bn .b-tl {
    padding-top: 1.2em;
    padding-bottom: 1.2em;
  }

  #s-bl table, #s-bl tbody, #s-bl td, #s-bl th, #s-bl thead, #s-bl tr, #s-cnt {
    display: block;
  }

  #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #s-bl tr {
    border-top: 1px solid #e3e7ed;
    border-color: var(--cc-cookie-table-border);
  }

  #s-bl td {
    border: none;
    padding-left: 35%;
    position: relative;
  }

  #s-bl td:before {
    white-space: nowrap;
    content: attr(data-column);
    color: #000;
    color: var(--cc-text);
    text-overflow: ellipsis;
    padding-right: .625em;
    position: absolute;
    left: 1em;
    overflow: hidden;
  }

  #cm .c-bn, .cc_div .c-bn {
    width: 100%;
    margin-right: 0;
  }

  #s-cnt #s-rall-bn {
    margin-left: 0;
  }

  .cc_div #c-bns {
    flex-direction: column;
  }

  #c-bns button + button, #s-cnt button + button {
    float: unset;
    margin-top: .625em;
    margin-left: 0;
  }

  #cm.box, #cm.cloud {
    width: auto;
    left: 1em;
    right: 1em;
  }

  #cm.cloud.left, #cm.cloud.right {
    margin: 0;
  }

  .cc_div .cloud #c-bns, .cc_div .cloud #c-inr, .cc_div .cloud #c-inr-i {
    width: auto;
    min-width: unset;
    display: block;
  }

  .cc_div .cloud #c-txt {
    font-size: .9em;
  }

  .cc_div .cloud #c-bns {
    margin-top: 1.625em;
  }
}

.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62em;
}

.cc_div.ie .bar #c-vln {
  padding-top: 0;
}

.cc_div.ie #cs {
  max-height: 37.5em;
  margin-top: -5.625em;
  position: relative;
  top: 0;
}

.cc_div.ie .bar #cs {
  max-height: 100%;
  margin-top: 0;
}

.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}

.cc_div.ie #c-s-in {
  top: 0;
}

.cc_div.ie .b-tg {
  margin-bottom: .7em;
  padding-left: 1em;
}

.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95em;
}

.cc_div.ie #s-bl table {
  overflow: auto;
}

.cc_div.ie .b-tg .c-tg {
  display: none;
}

.cc_div.ie .b-tg .c-tgl {
  vertical-align: middle;
  height: auto;
  margin-bottom: .2em;
  display: inline-block;
  position: relative;
}

.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3em 6.4em 1.3em 1.4em;
}

.cc_div.ie .bar #s-bl td:before {
  display: none;
}

.cc_div.ie .bar #s-bl td {
  padding: .8em .625em .8em 1.2em;
}

.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}

.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity= 0);
}

.cc_div.ie #cm-ov, .cc_div.ie #cs-ov {
  filter: alpha(opacity= 80);
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  color: #25254a;
  background-color: rgba(0, 0, 0, 0);
}

a:visited {
  color: #25254a;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

body {
  color: #252522;
  margin: 0;
  padding: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

p, ol, ul {
  line-height: 1.75;
}

.button {
  color: #25254a;
  flex-direction: row;
  align-content: start;
  justify-content: center;
  align-items: center;
  column-gap: .5rem;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: all .2s ease-out;
  display: flex;
}

.button--primary {
  color: #fff;
  background-color: #25254a;
}

.button--primary:hover, .button--primary:focus {
  color: #25254a;
  background-color: #fff;
}

.button--secondary {
  color: #25254a;
  background-color: #fff;
  border: .125rem solid #25254a;
  padding: .375rem .875rem;
}

.button--secondary:hover, .button--secondary:focus {
  background-color: #e3e3f1;
}

.card {
  background-color: #f1f5fb;
  padding: 2rem;
}

.card--outline {
  background-color: #fff;
  border: 2px solid #f1f5fb;
}

.card p:last-child {
  margin-bottom: 0;
}

.cc_div {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.container {
  max-width: 60rem;
  width: calc(100% - 2rem);
  justify-self: stretch;
  margin: auto;
}

@media screen and (max-width: 62rem) {
  .container {
    padding: env(safe-area-inset-top) calc(env(safe-area-inset-right)  + 1rem) env(safe-area-inset-bottom) calc(env(safe-area-inset-left)  + 1rem);
  }
}

.footer {
  color: #fff;
  background-color: #252522;
  padding: 4rem 0;
}

.footer__content {
  text-align: center;
  gap: 2rem;
  font-size: 1rem;
  display: grid;
}

.footer__copyright {
  color: #ccc;
  font-size: .875rem;
}

.header {
  padding: 2rem 0;
}

.header__content {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__claim {
  color: #7ea3d5;
  font-size: 1rem;
  display: none;
}

@media screen and (min-width: 40rem) {
  .header__claim {
    display: block;
  }
}

.header__logo {
  width: 204px;
  height: 140px;
}

.hero {
  min-height: 37.5rem;
  background-image: linear-gradient(4deg, rgba(255, 255, 255, 0) 0%, #fff 60%), url("hero-bg.e6c32dab.jpg");
  background-position: center, top;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  align-content: space-between;
  padding-bottom: 4rem;
  display: grid;
}

.hero__body {
  height: 100%;
  align-content: end;
  display: grid;
}

.hero__title {
  color: #25254a;
  font-size: 1.5rem;
}

@media screen and (min-width: 50rem) {
  .hero__title {
    font-size: 3rem;
  }
}

.hero__text {
  font-size: 1rem;
}

@media screen and (min-width: 50rem) {
  .hero__text {
    font-size: 1.125rem;
  }
}

.member {
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 2rem;
  display: flex;
}

.member__title {
  font-weight: bold;
}

.profile {
  width: 100%;
  justify-content: stretch;
  justify-items: center;
  gap: 2rem;
  display: grid;
}

@media screen and (min-width: 40rem) {
  .profile {
    grid-template-columns: auto auto;
    justify-items: start;
  }
}

.profile__picture {
  width: 200px;
  height: 200px;
  filter: drop-shadow(0 8px 32px rgba(96, 142, 204, .2));
  border-radius: 9999px;
  margin-top: -6rem;
}

.profile__info {
  width: 100%;
  gap: 1rem;
  display: grid;
}

.profile__contact {
  width: 100%;
  align-content: center;
  align-items: center;
  gap: .5rem 1.5rem;
  display: grid;
}

@media screen and (min-width: 40rem) {
  .profile__contact {
    grid-auto-flow: column;
  }
}

.profile__social {
  grid-auto-flow: column;
  align-content: center;
  justify-content: start;
  align-items: center;
  justify-items: start;
  gap: 1.5rem;
  display: grid;
}

.profile__social-link {
  padding-left: 0;
  padding-right: 0;
}

.section {
  grid-template-columns: 1fr;
  gap: 4rem;
  padding: 4rem 0;
  display: grid;
}

.section--blue-gradient {
  color: #fff;
  background: linear-gradient(131.48deg, #5686c8 23.74%, #7ea3d5 76.47%);
}

.section--blue-gradient .section__title {
  color: #fff;
}

.section--profile {
  padding: 3rem 0;
}

.section__title {
  color: #25254a;
  font-size: 1.25rem;
}

@media screen and (min-width: 50rem) {
  .section__title {
    font-size: 2rem;
  }
}

.section__subtitle {
  color: #25254a;
  margin: 1rem auto;
  font-size: 1.125rem;
}

@media screen and (min-width: 50rem) {
  .section__subtitle {
    font-size: 1.5rem;
  }
}

video {
  width: 100%;
  max-width: 640px;
  max-height: 480px;
}

.partners__logos {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.float-right {
  float: right;
}

.ml-auto {
  margin-left: auto;
}

.mt-0 {
  margin-top: 0;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-full {
  width: 100%;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-8 {
  gap: 2rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.object-cover {
  object-fit: cover;
}

.object-right-top {
  object-position: right top;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .md\:max-h-\[30vh\] {
    max-height: 30vh;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-8 {
    gap: 2rem;
  }
}

/*# sourceMappingURL=index.b4a2ff8b.css.map */
