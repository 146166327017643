$color-primary: #25254a;
$color-accent: #7ea3d5;
$color-body: #252522;
$color-white: #ffffff;

$font-family-body: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;

$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-2xl: 1.5rem;
$font-size-3xl: 2rem;
$font-size-4xl: 3rem;

$line-height-body: 1.75;
$line-height-heading: 1.2;

$transition-default: all 200ms ease-out;