.footer {
  padding: 4rem 0;
  background-color: $color-body;
  color: $color-white;
}

.footer__content {
  display: grid;
  gap: 2rem;
  font-size: $font-size-md;
  text-align: center;
}

.footer__copyright {
  font-size: $font-size-sm;
  color: darken($color-white, 20);
}
