.container {
  justify-self: stretch;
  max-width: 60rem;
  margin: auto;
  width: calc(100% - 2rem);

  @media screen and (max-width: 62rem) {
    padding: env(safe-area-inset-top) calc(env(safe-area-inset-right) + 1rem) env(safe-area-inset-bottom)
      calc(env(safe-area-inset-left) + 1rem);
  }
}
