@import "common/variables";
@import "common/normalize";
@import "common/global";

@import "/node_modules/vanilla-cookieconsent/dist/cookieconsent.css";

@import "components/button";
@import "components/card";
@import "components/cookies";
@import "components/container";
@import "components/footer";
@import "components/header";
@import "components/hero";
@import "components/member";
@import "components/profile";
@import "components/section";
@import "components/video";

@import "layouts/partners";

@tailwind utilities;
