$button-border-width: 0.125rem;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: start;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.5rem 1rem;
  color: $color-primary;
  text-decoration: none;
  transition: $transition-default;
}

.button--primary {
  background-color: $color-primary;
  color: $color-white;

  &:hover,
  &:focus {
    color: $color-primary;
    background-color: $color-white;
  }
}

.button--secondary {
  padding: calc(0.5rem - $button-border-width) calc(1rem - $button-border-width);
  background-color: $color-white;
  border: $button-border-width solid $color-primary;
  color: $color-primary;

  &:hover,
  &:focus {
    background-color: lighten($color-primary, 70);
  }
}
